<template>
  <div class="personal_tab_pane_content_pane1">
    <div class="personal_tab_pane_content_pane1_item">
      <span>昵称</span>
      <span>{{info.name}}</span>
    </div>
    <div class="personal_tab_pane_content_pane1_item">
      <span>手机号码</span>
      <span>{{info.phone}}</span>
    </div>
    <div class="personal_tab_pane_content_pane1_item">
      <span>性别</span>
      <span>{{info.sex?'男':'女'}}</span>
    </div>
    <div class="personal_tab_pane_content_pane1_item">
      <span>个性签名</span>
      <span>{{info.gxqm?info.gxqm:'无提拉，不跑步'}}</span>
    </div>
    <el-button>退出登录</el-button>
  </div>
</template>

<script>
	export default{
		data(){
			return{
        info:{}
			}
		},
    created() {
      this.getInfo()
    },
		methods:{
        async getInfo(){
          let res = await this.$http.post('/app/user/getUserInfo',this.$qs.stringify({userId:'7ced700e8f734c36affc3736aafb298e'}))
          console.log(res)
          this.info = res.data.data.userInfo
        }
		}
	}
</script>

<style scoped lang="scss">
.personal_tab_pane_content_pane1{
  .personal_tab_pane_content_pane1_item{
    padding-top: 30px;
    border-bottom: 1px solid #999;
    &:last-child{
      border: 0;
    }
    span{
      display: block;
      text-align: left;
      margin-bottom: 30px;
      &:first-child{
        color: #222;
        font-size: 24px;
      }
      &:last-child{
        color: #666;

      }
    }
  }
  .el-button{
    width: 355px;
    height: 60px;
    background-color: #E85D04;
    color: #fff;
    margin-top: 200px;
    margin-bottom: 50px;
  }
}

@media screen and (max-width:1200px){
  .personal_tab_pane_content_pane1{
    .personal_tab_pane_content_pane1_item{
      padding-top: 30px;
      border-bottom: 1px solid #999;
      &:last-child{
        border: 0;
      }
      span{
        display: block;
        text-align: left;
        margin-bottom: 30px;
        &:first-child{
          color: #222;
          font-size: 1em;
        }
        &:last-child{
          color: #666;

        }
      }
    }
    .el-button{
      width: 100%;
      height: 60px;
      background-color: #E85D04;
      color: #fff;
      margin-top: 50px;
      margin-bottom: 50px;
    }
  }
}
</style>
